import React, { useState, useEffect } from "react";

export default (props) => {
	const {name, label, className, options} = props;

	return (
		<div className={`mb-4 ${className}`}>
			<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={name}>
        {label}
      </label>
			<div className="relative">
				<select 
					className="shadow block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
					name={name}
				>
					{
						options.map((option, index) => <option key={`option-${index}`} value={option.value}>{option.name}</option>)
					}
				</select>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
					<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
				</div>
			</div>
		</div>
	)
}
