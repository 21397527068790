export const Paths = {
	login: "/login",
	mypage: "/mypage",
	editProfile: "/profile/edit",
	reservation: "/reservation",
	dashboard: "/dashboard",
	selectionInfo: "/selection/information",
	selectionSolution: "/selection/solution",
	showEvent: "/events/:id",
	showCaseStudy: "/case/studies/:id",
	caseStudyHistory: "/case/studies/history",
	caseStudyCorrection: "/case/studies/correction",
	showCompany: "/companies/:id",
	showMenter: "/menters/:id",
	entrySheetCorrection: "/entry/sheets/correction",
	ticketPurchase: "/tickets/purchase",
	showArticle: "/articles/:id",

	// admin path
	adminLogin: "/admin/login",
	adminDashboard: "/admin/dashboard",
	adminMentees: "/admin/mentee/list",
	adminEditMentee: "/admin/mentee/edit/:id",
	adminRegisterMentee: "/admin/mentee/register",
	adminMenters: "/admin/mentor/list",
	adminEditMenter: "/admin/mentor/edit/:id",
	adminRegisterMenter: "/admin/mentor/register",
	adminEvents: "/admin/event/list",
	adminEditEvent: "/admin/event/edit/:id",
	adminCreateEvent: "/admin/event/create",
	adminCaseStudies: "/admin/case/study/list",
	adminEditCaseStudy: "/admin/case/study/edit/:id",
	adminCreateCaseStudy: "/admin/case/study/create",
	adminCorrectionCaseStudy: "/admin/case/study/correction/:id",
	adminEntrySheets: "/admin/entry/sheet/list",
	adminCorrectionEntrySheet: "/admin/entry/sheet/correction/:id",
	adminCompanies: "/admin/company/list",
	adminEditCompany: "/admin/company/edit/:id",
	adminCreateCompany: "/admin/company/create",
	adminIndustries: "/admin/industry/list",
	adminEditIndustry: "/admin/industry/edit/:id",
	adminCreateIndustry: "/admin/industry/create",
	adminArticles: "/admin/article/list",
	adminEditArticle: "/admin/article/edit/:id",
	adminCreateArticle: "/admin/article/create",	
}